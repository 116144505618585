<template>
  <div class="tmsDeviceContainer">
      <vxe-toolbar export print custom style="padding-left:10px;">
          <template v-slot:buttons>
            <el-button @click="btnAddData">新增</el-button>
            <el-button @click="btnSaveData">保存</el-button>            
            <el-button @click="btnManageToken">Token</el-button>
            <div style="display:inline-block; width:10px;"></div>
            <el-dropdown @command="dropdownMenuEvent">
              <el-button>
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">                
                <el-dropdown-item command="remove">删除选中</el-dropdown-item>
                <el-dropdown-item command="import">导入数据</el-dropdown-item>
                <el-dropdown-item command="export">导出数据</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </vxe-toolbar>

        <vxe-table
          border
          resizable
          show-overflow
          keep-source
          highlight-hover-row
          ref="xTable"
          height="460"
          :export-config="{}"
          :loading="loading"
          :data="tableData"
          :edit-rules="validRules"
          :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
          @edit-actived="editActivedEvent"
          @edit-closed="editClosedEvent">
          <vxe-table-column type="checkbox" width="60" fixed="left"></vxe-table-column>
          <vxe-table-column type="seq" width="60" fixed="left"></vxe-table-column>
          <vxe-table-column field="SerialNumber" title="采集器序号" min-width="140" fixed="left" :edit-render="{name: 'ElSerialNumber'}"></vxe-table-column>
          <vxe-table-column field="EquipmentName" title="采集器名称" width="160" :edit-render="{name: 'ElEquipmentName',}"></vxe-table-column>
          <vxe-table-column field="DeptGroupID" title="机构名称" width="160"  :item-render="{name: 'ElOrganSelect', options: organList}"></vxe-table-column>
          <vxe-table-column field="IsEnable" title="是否启用" width="100" :cell-render="{name: 'ElIsEnable'}"></vxe-table-column>
          <vxe-table-column field="SerialStatus" title="采集器状态" min-width="140"></vxe-table-column>
          <vxe-table-column field="SerialLastTime" title="最新采集时间" width="220"></vxe-table-column>
          <vxe-table-column field="CustomCount" title="客户数量" min-width="140"></vxe-table-column>
          <vxe-table-column field="CheckCount" title="检测数量" min-width="140"></vxe-table-column>         
          <vxe-table-column field="CreatePerson" title="创建人员" min-width="140"></vxe-table-column>
          <vxe-table-column field="CreateDate" title="创建时间" width="220"></vxe-table-column>          
        </vxe-table>
  </div>
</template>

<script>

export default {
  data() {
    return {  
      loading: false,
      tableData: [],
      validRules: {            
        SerialNumber: [
          { required: true, message: '采集器序号必须填写' }
        ],
        EquipmentName: [
          { required: true, message: '采集器名称必须填写' },
        ],
        DeptGroupID: [
          { required: true, message: '机构号必须选择' },
        ],
      }
    };
  },
  mounted() {
   
  },
  created() {
    this.findOrganList()
    this.tableData = window.MOCK_DATA_LIST.slice(0, 10)
  },
  methods: {
    findOrganList:function(){

    },
    getDeviceManagerData()
    {

    }
  },

  components: {},
};
</script>


<style lang="less" scoped>

</style>